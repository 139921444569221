import * as React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Layout from '../components/layout'
import Seo from '../components/Seo'
import {
  Box,
  Flex,
  Container
} from '@chakra-ui/react'
import SliceZone from "../components/SliceZone/SliceZone"
import { PrismicRichText } from "@prismicio/react"

import SpringBanner from "../components/MountainBanner/SpringBanner/SpringBanner"


const ChamberPageTemplate = ({ data, location }) => {
  const document = data.prismicChamberPage.data;
  const pageLocation = location.pathname;

  return (
    <Layout pageLocation={pageLocation}>
      <Seo title={document.page_title.text} />
      
      {/* <SpringBanner 
        headline={document.page_title.text}
      /> */}
      <Container maxW="container.xl">
      {document.page_content?.richText[0]?.text && 
      (<Container maxW="container.xl">
        <Box my="8" p="12" textAlign={{base: 'center', md: 'left'}}>
          <PrismicRichText field={document.page_content.richText} />
        </Box>
      </Container>)
      }
        <Box>
        {document.chamber_member_logos?.document && (
            <Flex w="100%" h="100%" flexWrap="wrap" justifyContent="center">
              {document.chamber_member_logos.document.data.logo_group.map(
                (member, index) => {
                  return (
                  <Flex key={index} w={{base: '100%', md: '33.33%', lg: '25%', xl: '20%'}} p="8" alignItems="center" justifyContent="center">
                      <a href={member.link.url} target="_blank" rel="noopener noreferrer">
                        {member.logo.localFile?.publicURL.includes('.svg') ? 
                        (<img src={member.logo.localFile?.publicURL} alt={member.logo.alt} style={{marginLeft: 'auto', marginRight: 'auto', width: '100%' }} /> ) 
                        : (<GatsbyImage image={getImage(member.logo?.localFile)} alt={member.logo.alt} style={{height: 'unset', width: '100%'}} />)
                        }
                      </a>
                  </Flex>
                )}
              )}
            </Flex>
          )}
        </Box>
      </Container>

      <SliceZone sliceZone={document.body} />

    </Layout>
  )
}

export const query = graphql`
query ChamberPageQuery($uid: String!)
{
  prismicChamberPage(uid: {eq: $uid}) {
    data {
      page_title {
        text
      }
      page_content {
        richText
      }
      chamber_member_logos {
        document {
          ... on PrismicLogoGrid {
            id
            data {
              logo_group {
                logo {
                  alt
                  localFile {
                    childImageSharp {
                      gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
                    }
                    publicURL
                  }
                }
                link {
                  url
                }
              }
            }
          }
        }
      }
      body {
        ... on PrismicChamberPageDataBodyNewsCarousel {
          id
          slice_type
          items {
            title
            excerpt
            button_label
            button_link {
              url
              target
            }
            image {
              alt
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    layout: FULL_WIDTH
                    placeholder: BLURRED
                    quality: 8
                 )
                }
              }
            }
          }
        }
        ... on PrismicChamberPageDataBodyLogoCallToAction {
          id
          slice_type
          primary {
            logo {
              alt
              localFile {
                publicURL
              }
            }
            align
            content {
              richText
              text
            }
            button_link {
              url
              target
            }
            button_label
            text_area_text_color
            text_area_background_color
            image_area_background_color
            full_width_image
            button_solid_or_outline
          }
        }
        ... on PrismicChamberPageDataBodyFullImageSection {
          id
          slice_type
          primary {
            text_content {
              richText
            }
            image {
              alt
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED)
                }
              }
            }
            headline {
              text
            }
            button_link {
              url
            }
            button_label
            button_color
          }
        }
        ... on PrismicChamberPageDataBodyBlueBoxSection {
          id
          slice_type
          primary {
            section_title {
              text
            }
          }
          items {
            link_3 {
              url
            }
            link_2 {
              url
            }
            link_1 {
              url
            }
            label_3
            label_2
            label_1
            image_3 {
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED)
                }
              }
              alt
            }
            image_2 {
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
            }
            image_1 {
              alt
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED)
                }
              }
            }
          }
        }
        ... on PrismicChamberPageDataBodyAdvertisement {
          id
          slice_type
          primary {
            ad_link {
              url
            }
            desktop_ad_image {
              alt
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED)
                }
              }
            }
            mobile_ad_image {
              alt
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED)
                }
              }
            }
          }
        }
        ... on PrismicChamberPageDataBodyTabs {
          id
          slice_type
          items {
            tab_title
            tab_content {
              richText
            }
            chamber_member_logos {
              document {
                ... on PrismicLogoGrid {
                  id
                  data {
                    logo_group {
                      logo {
                        alt
                        localFile {
                          childImageSharp {
                            gatsbyImageData(placeholder: BLURRED)
                          }
                        }
                      }
                      link {
                        url
                      }
                    }
                  }
                }
              }
            }
          }
        }
        ... on PrismicChamberPageDataBodyStaffBoardBios {
          slice_type
          primary {
            section_title {
              text
            }
          }
          items {
            title
            photo {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    aspectRatio: 1
                    placeholder: BLURRED
                    transformOptions: {fit: COVER, cropFocus: CENTER}
                  )
                }
              }
              alt
            }
            name
            bio {
              richText
            }
          }
        }
        ... on PrismicChamberPageDataBodyRichTextSlice {
          id
          slice_type
          slice_label
          primary {
            image_link {
              url
              target
            }
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                }
              }
              alt
            }
            content1 {
              richText
            }
          }
        }
      }
    }
  }
}
`


export default ChamberPageTemplate;
